.market-parent-wrapper {
    padding: 0;
    margin: 0;
}

.market-wrapper {
    width: 100%;
    min-width: 100%;
    background: transparent;
    opacity: 1;
}

.market-wrapper .line {
    width: 100%;
    margin: 25px auto 0 auto;
    height: 1px;
}

.market-container {
    padding: 10px 0;
    text-align: center;
    width: 100%;
    text-align: center;
    margin: auto;
}
.market-container .market-card {
    width: 100%;
    /* min-height: 140px; */
    border-radius: 10px;
    margin: 0 auto;
}
.market-container .market-tabs {
    text-align: left;
    position: relative;
}
.market-container .market-tabs input {
    position: absolute;
    opacity: 0;
    margin: 0;
    padding: 0;
}

.market-container .market-tabs input + label:hover {
    color: #777;
}
.market-container .market-tabs .tabsShadow {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    position: absolute;
    height: 35px;
    z-index: 0;
    top: 0;
    pointer-events: none;
}

.market-container .content .item {
    display: flex;
    gap: 25px;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms;
    position: absolute;
    padding: 10px 5px;
}

.market-container section #content-1 {
    opacity: 1;
    visibility: visible;
    width: 94.5%;
}

.market-container .content .item#content-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* Adjust gap as needed */
}

.market-container #tab-1:checked ~ section #content-1,
.market-container #tab-2:checked ~ section #content-2,
.market-container #tab-3:checked ~ section #content-3 {
    opacity: 1;
    visibility: visible;
    width: 94.5%;
}

.market-container #tab-2:checked ~ section #content-1,
.market-container #tab-3:checked ~ section #content-1 {
    opacity: 0;
    visibility: hidden;
}

.market-container .market-tabs input + label {
    margin: 12px -4px;
    width: 15%;
    transition: all 200ms;
    text-align: center;
    border: none;
    border-bottom: 0.5pt solid #1d1e22;
    color: #e7e9f5;
    display: inline-flex;
    -webkit-box-pack: center;
    padding: 5pt 10pt;
    vertical-align: top;
    justify-content: flex-start;
    box-shadow: 1px 1px 2px 1px #231f20;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 8pt;
    margin-bottom: 0.5px;
    border-radius: 7px 7px 0 0 !important;
    border-top: 1px solid #3b3d3f;
    letter-spacing: 0.1666666667em !important;
    font-family: "antique-olive" !important;
    background: hsl(232deg 6% 17%);
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

.market-container .content {
    margin: 0px auto;
    text-align: left;
    line-height: 20px;
    /* height: 100% !important; */
    border-radius: 5px;
    box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    display: block;
    padding: 2.25rem;
    z-index: 2;
    border-top: 1px solid #3b3d3f;
    background: #1a1a1a;
    overflow: hidden; /* Prevents content from overflowing the container */
    word-break: break-all;
    position: relative;
}

#tab-1:checked ~ .content {
    height: 5270px !important;
}

#tab-2:checked ~ .content {
    height: 700px !important;
}

#tab-3:checked ~ .content {
    height: 700px !important;
}

.market-container .market-container .market-card {
    max-width: 600px;
    min-height: 140px;
    border-radius: 10px;
    border: none;
    margin: 100 auto;
    z-index: 4 !important;
    position: relative;
    background: linear-gradient(#141e30, #243b55);
    background: #16181a;
    border-top: 1px solid #3b3d3f;
    background: hsl(232deg 18% 8%);
}

.market-container .content p {
    margin: 10px 15px 10px 0;
    letter-spacing: 0.75px;
    font-size: 14pt;
    font-style: normal;
    color: #f0f2fc;
    line-height: 30px;
    margin-top: 10pt;
    font-family: "freight-display-pro";
    font-weight: 300 !important;
}
.market-container h2.tab-title.tab-success,
.market-container h2.tab-title.tab-primary,
.market-container h2.tab-title.tab-default {
    display: inline-flex;
    color: #fff;
    background-position: bottom center;
    margin-bottom: 0pt !important;
    margin-top: 6pt;
    height: 22pt;
    text-fill-color: transparent !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 0px;
    font-family: "Manrope" !important;
    font-weight: 800 !important;
}

.market-container h2.tab-title.tab-primary {
  background: linear-gradient(
    60deg,
    hsl(202deg 100% 75%),
    hsl(205deg 100% 64%),
    hsl(230deg 100% 75%),
    hsl(270deg 100% 72%)
  );
}

.market-container h2.tab-title.tab-success {
    background: linear-gradient(10deg, #02ce85, #02ceab);
}


.market-container h2.tab-title.tab-default {
    background: linear-gradient(10deg, #f7ec9c, #ff8651);
}

.market-container .market-tabs input {
    position: relative;
    opacity: 0;
    margin: auto !important;
    padding: 0;
}

.market-container label.tab.tab-primary {
    border-radius: 6pt 0 0 6pt;
}

.market-container #tab-1:checked + label {
    color: deepskyblue;
}

.market-container #tab-2:checked + label {
    color: #51a14c;
}

.market-container #tab-2:checked ~ .glider {
    background: linear-gradient(90deg, #51a14c 0%, #10c33e 100%);
    box-shadow: 0px 0px 8px 0px rgba(47, 187, 12, 0.62);
}

.market-container #tab-1 ~ .glider {
    left: 1.3%;
}

.market-container #tab-1:checked ~ .glider {
  left: 1.3%;
}

.market-container #tab-2:checked ~ .glider {
  left: 16.7%;
}

.market-container #tab-3:checked ~ .glider {
    background: linear-gradient(
        90deg,
        #faffcc 0%,
        #f5eea3 10%,
        #ffe48a 40%,
        #ffb54d 65%,
        #ff974d 85%,
        #ff8052 100%
    );
    left: 32.2%;
    box-shadow: 0px 0px 8px 0px hsl(17.72deg 100% 70% / 70%);
}

.market-container #tab-3:checked + label {
    color: #f6d359;
}

.market-container .glider {
  width: 13%;
  padding: 0px 13px;
  height: 4.5px;
  border-radius: 0 0 1px 1px;
  position: absolute;
  box-shadow: 0px 0px 8px 0px hsl(262deg 100% 70% / 70%);
  background: linear-gradient(
    113deg,
    hsl(260deg 100% 64%) 0%,
    hsl(190deg 100% 55%) 100%
  );
  transition: all 200ms;
  top: 40px;
  z-index: 2;
}

.market-container span.numit {
  font-size: 12pt;
  font-family: "muli";
  font-weight: 600;
  text-shadow: 0.5px 0.5px 0.5px #e7e9f5;
  color: #0e101a;
  margin-right: 2px;
  margin-left: -2px;
  padding: 0;
  color: aliceblue;
  text-shadow: 0.5pt 0.5pt 0.5pt #2d3748;
}


.parent-secondary-market {
    width: 100% !important;
}

.parent-secondary-market * {
    color: #fff !important;
}

.sn-pd-text {
    text-decoration: none;
    color: #f6d36a !important;
    font-weight: bold;
}

.parent-secondary-market .tag-text {
    padding: 8px;
    background-color: #414141;
    text-decoration: none;
    border-radius: 8px;
    color: #f6d36a !important;
    font-weight: bold;
}

.parent-secondary-market .seller-pic {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.parent-secondary-market .parent-ask-hbid .ask-text-hbid {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-size: 14px !important;
}

.parent-secondary-market .parent-ask-hbid .take-btn-hbid {
    cursor: pointer;
    color: #000 !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    background-color: #f6d36a;
    box-shadow: 0px 0px 17px rgba(246, 211, 106, 0.55);
    margin-right: 6.5%;
}

.parent-secondary-market .bid-button {
    padding: 9px 17px 9px 17px;
    border: none;
    border-radius: 6px;
    background-color: #f6d36a;
    font-weight: bold;
    transition: 250ms all ease-in-out;
    color: #000 !important;
}

.parent-secondary-market .bid-button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.7);
}


/* Bid Success Modal */
.parent-parent-invest-failed .gotItButton {
    width: 100%;
    border: none;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
}

.parent-parent-invest-failed .svg-parent img {
    width: 70px;
    margin-bottom: 25px;
}

.parent-parent-invest-failed .gotItButton:hover {
    box-shadow: 0 0 12px rgba(246, 211, 106, 0.6);
}