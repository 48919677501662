:root {
    --text-color: #dfe0e0;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3 - 20px), 1fr)); /* Three columns of equal width */
    gap: 20px; /* Add gap between the carousel items */
    overflow-x: hidden; /* Enable horizontal scrolling for additional items */
    overflow-y: hidden;
    padding: 20px; /* Add padding to the grid container */
    margin-top: -8%;
}

/* Style for individual carousel items */
.grid-container > div {
    min-width: 0; /* Set a fixed width for each carousel item */
}

.card {
    max-width: 100%;
    border: 0;
    width: 100%;
    background: #1d1d1d;
    /* background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg'); */
    background-position: bottom center;
    background-repeat: no-repeat;
    /* margin-inline: auto; */
}
  
.container-card {
    position: relative;
    border: 2px solid transparent;
    /* background: linear-gradient(71deg, #080509, #1a171c, #080509); */
    background-clip: padding-box;
    border-radius: 45px;
    padding: 15px;
}

.container-card svg {
    width: 50px;
    height: 50px;
}

.child-container-card2 svg {
    width: 23px;
    height: 23px;
    margin: 0 auto;
}

.card .container-card .child-container-card {
    display: grid;
    grid-template-columns: 15% 85%;
}

.card .container-card .child-container-card2 {
    display: grid;
    grid-template-columns: 10% 60% 10% 20%;
    margin-top: 10px;
    cursor: pointer;
}

.card .container-card .child-container-card .card-title {
    margin-top: 5.5%;
}

.child-container-card2 .card-description {
    margin: 0 auto;
}

.child-container-card2 .title {
    width: 100%;
}

.bg-green-box,
.bg-yellow-box {
  position: relative;
}

.bg-green-box::after,
.bg-yellow-box::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 5px;
}

.bg-green-box::after {
    background-color: #1a1a1a;
    /* background-color: #1d1d1d; */
    /* background: linear-gradient(71deg, #0d1212, #f6d359, #0d1212); */
}

/* .bg-green-box:hover:after {
    background: linear-gradient(1deg, #0d1212, #f6d359, #0d1212);
} */

.bg-yellow-box::after {
    background-color: #1a1a1a;
    /* background-color: #1d1d1d; */
    /* background: linear-gradient(71deg, #110e0e, #f6d359, #110e0e); */
}

.img-ttf-child {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.card-title {
    font-weight: 600;
    color: white;
    letter-spacing: -0.02em;
    line-height: 10px;
    font-style: normal;
    font-size: 16px;
}
  
.card-description {
    font-weight: 600;
    line-height: 26px;
    /* color: hsla(0, 0%, 100%, 0.5); */
    color: #b3b3b3;
    font-size: 12px;
    max-width: 100%;
}

.child-container-card2 .investment-type {
    border-radius: 6px;
    color: #ffc800;
}
    
.child-container-card2 .yield {
    color: #ffc800;
}

.slick-dots li button::before {
    color: #f00;
  }
  
.slick-dots li.slick-active button::before {
    color: #ffc800; /* Optional: Change active dot color */
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .container-card {
        position: relative;
        border: 2px solid transparent;
        background: linear-gradient(71deg, #080509, #1a171c, #080509);
        background-clip: padding-box;
        border-radius: 45px;
        padding: 10px;
    }

    .container-card svg {
        width: 60px !important;
        height: 60px !important;
    }

    .container-card svg {
        width: 70px;
        height: 70px;
    }

    .card-title {
        line-height: 0px;
        font-size: 18px;
    }

    .card-description {
        font-size: 13px;
    }

    .card .container-card .child-container-card {
        grid-template-columns: 20% 80%;
    }
    
    .card .container-card .child-container-card .card-title {
        margin-top: 13%;
    }
}

@media only screen and (min-width: 703px) and (max-width: 991px) {
    .grid-container {
        display: grid;
        grid-template-columns: auto auto; /* Three columns of equal width */
        gap: 10px; /* Add gap between the carousel items */
    }
    
    .grid-container > .parent-card:nth-child(2) {
        display: none;
    }

    .container-card {
        position: relative;
        border: 2px solid transparent;
        background: linear-gradient(71deg, #080509, #1a171c, #080509);
        background-clip: padding-box;
        border-radius: 45px;
        padding: 10px;
    }

    .container-card svg {
        width: 60px !important;
        height: 60px !important;
    }

    .container-card svg {
        width: 70px;
        height: 70px;
    }

    .card-title {
        line-height: 0px;
        font-size: 20px;
    }

    .card-description {
        font-size: 13px;
    }

    .card .container-card .child-container-card {
        grid-template-columns: 30% 80%;
    }
    
    .card .container-card .child-container-card .card-title {
        margin-top: 10%;
    }
}

@media only screen and (min-width: 415px) and (max-width: 702px) {

}

@media only screen and (min-width: 127px) and (max-width: 414px) {

}