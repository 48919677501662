.parent-secondary-market {
    width: 100% !important;
}

.parent-secondary-market * {
    color: #fff !important;
}

.sn-pd-text {
    text-decoration: none;
    color: #f6d36a !important;
    font-weight: bold;
}

.parent-secondary-market .tag-text {
    padding: 8px;
    background-color: #414141;
    text-decoration: none;
    border-radius: 8px;
    color: #f6d36a !important;
    font-weight: bold;
}

.parent-secondary-market .seller-pic {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.parent-secondary-market .parent-ask-hbid .ask-text-hbid {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-size: 14px !important;
}

.parent-secondary-market .parent-ask-hbid .take-btn-hbid {
    cursor: pointer;
    color: #000 !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    background-color: #f6d36a;
    box-shadow: 0px 0px 17px rgba(246, 211, 106, 0.55);
    margin-right: 6.5%;
}