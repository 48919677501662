.login-register-section-parent {
    font-weight: 300;
    font-size: 15px;
    line-height: 1.7;
    color: #c4c3ca;
    overflow-x: hidden;
}

.login-register-section-parent a {
	cursor: pointer;
  transition: all 200ms linear;
}
.login-register-section-parent a:hover {
	text-decoration: none;
}
.login-register-section-parent .link {
  color: #f6d36a;
}
.login-register-section-parent .link:hover {
  color: #f6d36a;
}
.login-register-section-parent p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
}
.login-register-section-parent h4 {
  font-weight: 600;
}
.login-register-section-parent h6 span{
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.login-register-section-parent .section{
  position: relative;
  width: 100%;
  display: block;
}
.login-register-section-parent .full-height{
  min-height: 100vh;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
}
.login-register-section-parent .checkbox:checked + label,
.login-register-section-parent .checkbox:not(:checked) + label{
  position: relative;
  display: block;
  text-align: center;
  width: 60px;
  height: 16px;
  border-radius: 8px;
  padding: 0;
  margin: 10px auto;
  cursor: pointer;
  background-color: #ffeba7;
}
.login-register-section-parent .checkbox:checked + label:before,
.login-register-section-parent .checkbox:not(:checked) + label:before{
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #f6d36a;
  background-color: #102770;
  font-family: 'unicons';
  content: '\1F86C';
  z-index: 20;
  top: -10px;
  left: -10px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  transition: all 0.5s ease;
}
.login-register-section-parent .checkbox:checked + label:before {
  transform: translateX(44px) rotate(-270deg);
}


.login-register-section-parent .card-3d-wrap {
  position: relative;
  width: 540px;
  max-width: 100%;
  height: 470px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 800px;
  margin-top: 60px;
}
.login-register-section-parent .card-3d-wrapper {
  width: 100%;
  height: 100%;
  position:absolute;    
  top: 0;
  left: 0;  
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 600ms ease-out; 
}
.login-register-section-parent .card-front, .card-back {
  width: 100%;
  height: 100%;
  background-color: #2a2b38;
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat.svg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 300%;
  position: absolute;
  border-radius: 6px;
  left: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.login-register-section-parent .card-back {
  transform: rotateY(180deg);
}
.login-register-section-parent .checkbox:checked ~ .card-3d-wrap .card-3d-wrapper {
  transform: rotateY(180deg);
}
.login-register-section-parent .center-wrap{
  position: absolute;
  width: 100%;
  padding: 0 35px;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 35px) perspective(100px);
  z-index: 20;
  display: block;
}


.login-register-section-parent .form-group{ 
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
}
.login-register-section-parent .form-style {
    padding: 13px 20px;
    padding-left: 55px;
    height: 48px;
    width: 100%;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    outline: none;
    color: #c4c3ca;
    background-color: #1f2029;
    border: none;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    box-shadow: 0 4px 8px 0 rgba(21,21,21,.2);
}
.login-register-section-parent .form-style:focus,
.login-register-section-parent .form-style:active {
    border: none;
    outline: none;
    box-shadow: 0 4px 8px 0 rgba(21,21,21,.2);
}
.login-register-section-parent .input-icon {
    position: absolute;
    top: 0;
    left: 18px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: #f6d36a;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.login-register-section-parent .form-group input:-ms-input-placeholder {
    color: #000;
    opacity: 0.7;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input::-moz-placeholder {
    color: #c4c3ca;
    opacity: 0.7;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input:-moz-placeholder  {
    color: #c4c3ca;
    opacity: 0.7;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input::-webkit-input-placeholder {
    color: #c4c3ca;
    opacity: 0.7;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input:focus:-ms-input-placeholder {
    opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input:focus:-moz-placeholder {
    opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.login-register-section-parent .form-group input:focus::-webkit-input-placeholder {
    opacity: 0;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.login-register-section-parent .btn {  
    border-radius: 4px;
    height: 44px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition : all 200ms linear;
    transition: all 200ms linear;
    padding: 0 30px;
    letter-spacing: 1px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: #f6d36a;
    color: #000;
    box-shadow: 0 8px 24px 0 rgba(255,235,167,.2);
}
.login-register-section-parent .btn:active,
.login-register-section-parent .btn:focus {  
  /* background-color: #102770;
  color: #ffeba7; */
  box-shadow: 0 0 24px 0 rgba(246, 211, 106, 0.75);
}
.login-register-section-parent .btn:hover {  
  /* background-color: #102770;
  color: #ffeba7; */
  box-shadow: 0 0 24px 0 rgba(246, 211, 106, 0.75);
}

.parent-parent-email-already-used {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: inline-block;
}

.parent-email-already-used {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 550px;
    max-width: 550px;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 10px;

    animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
    10%, 90% {
      transform: translateX(-50%) translateY(-50%);
    }
  
    20%, 80% {
      transform: translateX(-48%) translateY(-48%);
    }
  
    30%, 70% {
      transform: translateX(-52%) translateY(-52%);
    }
  
    40%, 60% {
      transform: translateX(-48%) translateY(-48%);
    }
  
    50% {
      transform: translateX(-50%) translateY(-50%);
    }
  }

.parent-email-already-used .svg-parent svg {
    width: 70px;
    margin-bottom: 25px;
}

.parent-email-already-used .title {
    font-size: 24px;
    color: #f6d36a;
    font-weight: bold;
    display: block;
}

.parent-email-already-used .text {
    font-weight: 200;
    font-size: 16px;
    color: #C6C6C6;
}

.parent-email-already-used .button {
    border: none;
    width: 35%;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
}

.parent-email-already-used .button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parent-parent-register-success {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: inline-block;
}

.parent-register-success {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 550px;
  max-width: 550px;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;

  animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
      transform: translateX(-50%) translateY(-50%);
  }
  
  20%, 80% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  30%, 70% {
      transform: translateX(-52%) translateY(-52%);
  }
  
  40%, 60% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  50% {
      transform: translateX(-50%) translateY(-50%);
  }
}

.parent-register-success .svg-parent img {
  width: 70px;
  margin-bottom: 25px;
}

.parent-register-success .title {
  font-size: 24px;
  color: #f6d36a;
  font-weight: bold;
  display: block;
}

.parent-register-success .text {
  font-weight: 200;
  font-size: 16px;
  color: #C6C6C6;
}

.parent-register-success .button {
  border: none;
  width: 35%;
  padding: 5px 0;
  font-size: 16px;
  background-color: #f6d36a;
  margin-top: 10px;
  border-radius: 5px;
  transition: 300ms all ease-in-out;
}

.parent-register-success .button:hover {
  box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parent-parent-register-failed {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: inline-block;
}

.parent-register-failed {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 550px;
  max-width: 550px;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;

  animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
      transform: translateX(-50%) translateY(-50%);
  }
  
  20%, 80% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  30%, 70% {
      transform: translateX(-52%) translateY(-52%);
  }
  
  40%, 60% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  50% {
      transform: translateX(-50%) translateY(-50%);
  }
}

.parent-register-failed .svg-parent svg {
  width: 70px;
  margin-bottom: 25px;
}

.parent-register-failed .title {
  font-size: 24px;
  color: #f6d36a;
  font-weight: bold;
  display: block;
}

.parent-register-failed .text {
  font-weight: 200;
  font-size: 16px;
  color: #C6C6C6;
}

.parent-register-failed .button {
  border: none;
  width: 35%;
  padding: 5px 0;
  font-size: 16px;
  background-color: #f6d36a;
  margin-top: 10px;
  border-radius: 5px;
  transition: 300ms all ease-in-out;
}

.parent-register-failed .button:hover {
  box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parent-parent-login-success {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: inline-block;
}

.parent-login-success {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 550px;
  max-width: 550px;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;

  animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
      transform: translateX(-50%) translateY(-50%);
  }
  
  20%, 80% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  30%, 70% {
      transform: translateX(-52%) translateY(-52%);
  }
  
  40%, 60% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  50% {
      transform: translateX(-50%) translateY(-50%);
  }
}

.parent-login-success .svg-parent svg {
  width: 70px;
  margin-bottom: 25px;
}

.parent-login-success .title {
  font-size: 24px;
  color: #f6d36a;
  font-weight: bold;
  display: block;
}

.parent-login-success .text {
  font-weight: 200;
  font-size: 16px;
  color: #C6C6C6;
}

.parent-login-success .button {
  border: none;
  width: 35%;
  padding: 5px 0;
  font-size: 16px;
  background-color: #f6d36a;
  margin-top: 10px;
  border-radius: 5px;
  transition: 300ms all ease-in-out;
}

.parent-login-success .button:hover {
  box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parent-parent-login-failed {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: inline-block;
}

.parent-login-failed {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 550px;
  max-width: 550px;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;

  animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
      transform: translateX(-50%) translateY(-50%);
  }
  
  20%, 80% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  30%, 70% {
      transform: translateX(-52%) translateY(-52%);
  }
  
  40%, 60% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  50% {
      transform: translateX(-50%) translateY(-50%);
  }
}

.parent-login-failed .svg-parent svg {
  width: 70px;
  margin-bottom: 25px;
}

.parent-login-failed .title {
  font-size: 24px;
  color: #f6d36a;
  font-weight: bold;
  display: block;
}

.parent-login-failed .text {
  font-weight: 200;
  font-size: 16px;
  color: #C6C6C6;
}

.parent-login-failed .button {
  border: none;
  width: 35%;
  padding: 5px 0;
  font-size: 16px;
  background-color: #f6d36a;
  margin-top: 10px;
  border-radius: 5px;
  transition: 300ms all ease-in-out;
}

.parent-login-failed .button:hover {
  box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}