@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Quicksand:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend Deca", sans-serif !important;
}
body{
	font-family: "Lexend Deca", sans-serif !important;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	color: #ececee;
	background-color: #01030a;
	overflow: hidden;
  background-image: url('../../images/dark-fish-skin.png');
  background-position: center;
  background-repeat: repeat;
  background-size: .38%;
  width: 100%;
  overflow-y: scroll;
}
h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 7vw;
  line-height: 1;
  color: #ffeba7;
  text-align: center;
  -webkit-text-stroke: 2px #ffeba7;
  text-stroke: 2px #ffeba7;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: transparent;
}
.wrapper-parent {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: rgba(1, 3, 10, 0.85);
}
.wrapper-parent  .wrapper {
  position: relative;
  max-width: 1420px;
  padding: 0px 0px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-parent .wrapper .logo {
  position: relative;
  height: 200px;
}
.wrapper-parent .wrapper .logo a img {
  height: 45px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.wrapper-parent .wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
  margin-left: 5px;
}

.nav-links li Link {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 6px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li .Link2 {
  display: inline;
}
.nav-links li a{
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 6px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  background: #3A3B3C;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #01030A;
  width: 450px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  margin-left: -19.5%;
  border: 1.75px solid #544C4C;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.nav-links .user-drop-menu {
  padding: 0 0 35px 0;
}

.nav-links .user-drop-menu li:first-child {
  text-align: center;
}

.nav-links .user-drop-menu li .account-text {
  font-size: 20px;
  font-weight: bold;
}

.nav-links .user-drop-menu .level-kyc span {
  padding: 10px;
  border: 2px solid #414141;
  border-radius: 12px;
  color: #F6D36A;
  font-weight: bold;
}

.nav-links .user-drop-menu .level-kyc span:last-child,
.nav-links .user-drop-menu .level-kyc span:nth-last-child(2)  {
  margin-left: 5px;
}

.user-drop-menu .level-kyc {
  text-align: center;
}

.nav-links .user-drop-menu .account-details {
  padding: 10px 15px 0 15px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.nav-links .user-drop-menu .account-details .account-details-title {
  display: block;
}

.nav-links .user-drop-menu .account-details svg {
  margin: 0 0 0 20px;
}

.nav-links .user-drop-menu .account-details .parent-span-firstChild {
  display: block;
  margin-top: -5px !important;
}

.nav-links .user-drop-menu .account-details .parent-span {
  display: block;
  transition: 200ms all ease;
}

.nav-links .user-drop-menu .account-details .parent-span:hover {
  background-color: #F6D36A;
  color: #000;
}

.nav-links .user-drop-menu .noti-account-details .parent-span {
  display: block;
  transition: 200ms all ease;
}

.nav-links .user-drop-menu .noti-account-details .parent-span .child-span span {
  /* Insert the code for arrow key */
}

.nav-links .user-drop-menu .noti-account-details .parent-span:hover .child-span span {
  /* Insert the code for arrow key when hovered */
}

.nav-links .user-drop-menu .noti-account-details .parent-span:hover {
    background-color: #01030a;
    color: #fff;
}

.nav-links .user-drop-menu .account-details .child-span {
  font-size: 14px;
  margin: 0 0 0 30px;
  font-weight: 400;
}

.nav-links .tokenize-button a {
  background-color: #F6D36A;
  color: #000;
  transition: 300ms all ease-in-out !important;
  padding-left: 10px;
  padding-right: 10px;
}

.nav-links .tokenize-button a:hover {
  background-color: #F6D36A;
  box-shadow: 0 0 20px rgba(246, 211, 106, 0.85);
  color: #000;
}

.nav-links .tokenize-button a:hover {
  background-color: #F6D36A;
  box-shadow: 0 0 20px rgba(246, 211, 106, 0.65);
  color: #000;
}

.nav-links .noti-drop-menu .noti-child-span {
  display: block;
  margin-top: -15px !important;
  margin-left: 82px !important;
  color: #777777;
  font-size: 13px !important;
}

.nav-links .noti-drop-menu .noti-account-details .hover-arrow {
  font-size: 24px !important;
  margin-left: 2%;
  color: transparent;
  transition: 150ms all ease-in-out !important;
}

.nav-links .noti-drop-menu .noti-account-details:hover .hover-arrow {
  display: inline;
  color: #f6d36a;
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover{
  color: #f2f2f2;
}
.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}
nav input{
  display: none;
}