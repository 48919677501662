.parent-parent-project-details-page {
    width: 100%;
    text-align: center;
}

.parent-parent-project-details-page .title {
    font-size: 20px;
    font-weight: bold;
}

.parent-parent-project-details-page .proposing-person {
    font-size: 14px;
}

.parent-parent-project-details-page .proposing-person span {
    text-decoration: underline;
    cursor: pointer;
}

.parent-parent-project-details-page .parent-project-details-page {
    display: flex; /* Set the parent to flexbox */
    margin-top: 80px;
}
  
.parent-parent-project-details-page .child1 {
    flex: 6 0 60%; /* First child takes 60% of available space */
}

.parent-parent-project-details-page .child1 img {
    width: 800px;
    height: 490px;
}
  
.parent-parent-project-details-page .child2 {
    flex: 4 0 40%; /* Second child takes 40% of available space */
}

.parent-parent-project-details-page .child2 .line {
    width: 100%;
    height: 5px;
    background-color: #f6d36a;
    margin-bottom: 20px;
}

.parent-parent-project-details-page .child2 .child2-first-row {
    text-align: left;
}

.parent-parent-project-details-page .child2 .child2-first-row .child2-first-row-title {
    color: #f6d36a;
    font-size: 28px;
    font-weight: bold;
}

.parent-parent-project-details-page .child2 .child2-first-row .child2-first-row-text {
    margin-top: -20px;
    color: #A1A1A1;
}

.parent-parent-project-details-page .parent-kpi-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    margin-top: 30px;
}
 
.parent-parent-project-details-page .parent-kpi-div .kpi-value {
    font-size: 24px;
}

.parent-parent-project-details-page .parent-kpi-div .kpi-title {
    font-size: 14px;
    margin-top: -20px;
    color: #A1A1A1;
}

.parent-parent-project-details-page .invest-button {
    border: none;
    width: 100%;
    padding: 10px;
    background-color: #F6D36A;
    border-radius: 8px;
    margin-top: 15px;
    transition: 300ms all ease-in-out;
    font-weight: bold;
}

.parent-parent-project-details-page .invest-button:hover {
    box-shadow: 0 0 25px rgba(246, 211, 106, 0.7);
}

.parent-parent-project-details-page .verified-div {
    overflow: auto;
    margin-top: 35px;
}

.parent-parent-project-details-page .verified-div .verified-div-child1 img {
    width: 52px;
    float: left;
}

.parent-parent-project-details-page .verified-div .verified-div-child2 {
    float: left;
    text-align: left;
    margin-left: 20px;
}

.parent-parent-project-details-page .verified-div .verified-div-child2 .verified-title {
    font-size: 14px;
    font-weight: bold;
}

.parent-parent-project-details-page .verified-div .verified-div-child2 .verified-text {
    font-size: 12px;
    color: #A1A1A1;
    margin-top: -15px;
    width: 350px;
}

.parent-parent-project-details-page .parent-two-tags-div {
    display: flex;
    justify-content: center;
    padding: 0 28px;
    margin-top: 5px;
}

.parent-parent-project-details-page .two-tags-div {
    width: 100%;
    text-align: left;
}

.parent-parent-project-details-page .two-tags-div span {
    background-color: #414141;
    color: #F6D36A;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 12px;
}

.parent-parent-project-details-page .two-tags-div .two-tags-div-text {
    margin-left: 15px;
}

.parent-parent-invest-failed {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.parent-invest-failed {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 550px;
    max-width: 550px;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    background-color: #1a1a1a;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
  
    animation: slideShow 0.40s cubic-bezier(.36,.07,.19,.97) both;
}
  
@keyframes slideShow {
    0% {
        min-width: 0;
        max-width: 0;
    }

    100% {
        min-width: 550px;
        max-width: 550px;
    }
}
  
.parent-invest-failed .svg-parent .investPhoto {
    width: 500px;
    margin-bottom: 25px;
    animation: slideShow2 0.40s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes slideShow2 {
    0% {
        width: 0;
    }

    100% {
        width: 500px;
    }
}

.parent-invest-failed .invest-data {
    padding: 0 30px;
}
  
.parent-invest-failed .title {
    font-size: 24px;
    color: #f6d36a;
    font-weight: bold;
    display: block;
}
  
.parent-invest-failed .text {
    font-weight: 200;
    font-size: 16px;
    color: #C6C6C6;
}

.parent-invest-failed .first-input {
    margin-top: 0px !important;
    color: #fff;
}

.parent-invest-failed input {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #F6D36A;
    background-color: #212121;
    border-radius: 12px;
    margin-top: 25px;
    transition: 200ms all ease-in-out;
    color: #a8a8a8;
}
  
.parent-invest-failed .two-buttons-invest-modal .closeInvestButton {
    border: none;
    width: 35%;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
    display: block;
    width: 100%;
    margin-top: 35px;
}

.parent-invest-failed .two-buttons-invest-modal .cancelInvestButton {
    background-color: #d9534f;
}

.parent-invest-failed input[type="text"]:focus {
    outline: none;
    box-shadow: 0 0 14px rgba(246, 211, 106, 0.55);
}
  
.parent-invest-failed .two-buttons-invest-modal .closeInvestButton:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.7);
}

.parent-invest-failed .two-buttons-invest-modal .cancelInvestButton:hover {
    box-shadow: 0 0 18px rgba(217, 83, 79, 0.7);
}

.parent-invest-failed .two-buttons-invest-modal {
    display: flex;
    justify-content: center;
}

.parent-invest-failed .two-buttons-invest-modal button {
    flex: 0 0 50%; /* flex-grow, flex-shrink, flex-basis */
    margin-right: 10px; /* Optional: Adjust spacing between buttons */
}


.parent-parent-maxToken-failed {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.parent-maxToken-failed {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 550px;
  max-width: 550px;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;

  animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
      transform: translateX(-50%) translateY(-50%);
  }
  
  20%, 80% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  30%, 70% {
      transform: translateX(-52%) translateY(-52%);
  }
  
  40%, 60% {
      transform: translateX(-48%) translateY(-48%);
  }
  
  50% {
      transform: translateX(-50%) translateY(-50%);
  }
}

.parent-maxToken-failed .svg-parent svg {
  width: 70px;
  margin-bottom: 25px;
}

.parent-maxToken-failed .title {
  font-size: 24px;
  color: #f6d36a;
  font-weight: bold;
  display: block;
}

.parent-maxToken-failed .text {
  font-weight: 200;
  font-size: 16px;
  color: #C6C6C6;
}

.parent-maxToken-failed .button {
  border: none;
  width: 35%;
  padding: 5px 0;
  font-size: 16px;
  background-color: #f6d36a;
  margin-top: 10px;
  border-radius: 5px;
  transition: 300ms all ease-in-out;
}

.parent-maxToken-failed .button:hover {
  box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parent-parent-maxToken-failed {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: inline-block;
}
  
.parent-maxToken-failed {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 550px;
    max-width: 550px;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 10px;
  
    animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}
  
@keyframes shake {
    10%, 90% {
        transform: translateX(-50%) translateY(-50%);
    }
    
    20%, 80% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    30%, 70% {
        transform: translateX(-52%) translateY(-52%);
    }
    
    40%, 60% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    50% {
        transform: translateX(-50%) translateY(-50%);
    }
}
  
.parent-maxToken-failed .svg-parent svg {
    width: 70px;
    margin-bottom: 25px;
}
  
.parent-maxToken-failed .title {
    font-size: 24px;
    color: #f6d36a;
    font-weight: bold;
    display: block;
}
  
.parent-maxToken-failed .text {
    font-weight: 200;
    font-size: 16px;
    color: #C6C6C6;
}
  
.parent-maxToken-failed .button {
    border: none;
    width: 35%;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
}
  
.parent-maxToken-failed .button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}


.parent-parent-investment-success {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.parent-investment-success {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 550px;
    max-width: 550px;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 10px;
  
    animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}
  
@keyframes shake {
    10%, 90% {
        transform: translateX(-50%) translateY(-50%);
    }
    
    20%, 80% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    30%, 70% {
        transform: translateX(-52%) translateY(-52%);
    }
    
    40%, 60% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    50% {
        transform: translateX(-50%) translateY(-50%);
    }
}
  
.parent-investment-success .svg-parent img {
    width: 70px;
    margin-bottom: 25px;
}
  
.parent-investment-success .title {
    font-size: 24px;
    color: #f6d36a;
    font-weight: bold;
    display: block;
}
  
.parent-investment-success .text {
    font-weight: 200;
    font-size: 16px;
    color: #C6C6C6;
}
  
.parent-investment-success .button {
    border: none;
    width: 35%;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
}
  
.parent-investment-success .button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}