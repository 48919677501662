@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.footer-parent-parent {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.footer-parent {
  display: flex;
  background: #333;
  justify-content: flex-end;
  align-items: flex-end;
}

.footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

.parent-footer .child-section {
  background-color: #111827;
}

.parent-footer ul {
  list-style-type: none;
}

.parent-footer a {
  text-decoration: none;
  color: #fff;
}

.parent-footer .w-full {
  width: 100%;
}

.parent-footer .flex {
  display: flex;
}

.parent-footer .justify-center {
  justify-content: center;
}

.parent-footer .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.parent-footer .mt-60 {
  margin-top: 15rem/* 240px */;
}

.parent-footer .shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.parent-footer .max-w-screen-xl {
  max-width: 1280px;
}

.parent-footer .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.parent-footer .p-4 {
  padding: 1rem/* 16px */;
}

.parent-footer .items-center {
  align-items: center;
}

.parent-footer .mb-4 {
  margin-bottom: 1rem/* 16px */;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem/* 12px */ * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem/* 12px */ * calc(1 - var(--tw-space-x-reverse)));
}

.parent-footer .self-center {
  align-self: center;
}

.parent-footer .text-2xl {
  font-size: 1.5rem/* 24px */;
  line-height: 2rem/* 32px */;
}

.parent-footer .font-semibold {
  font-weight: 600;
}

.parent-footer .whitespace-nowrap {
  white-space: nowrap;
}

.parent-footer .flex-wrap {
  flex-wrap: wrap;
}

.parent-footer .mb-6 {
  margin-bottom: 1.5rem/* 24px */;
}

.parent-footer .text-sm {
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
}

.parent-footer .font-medium {
  font-weight: 500;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.my-6 {
  margin-top: 1.5rem/* 24px */;
  margin-bottom: 1.5rem/* 24px */;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.block {
  display: block;
}

@media (min-width: 640px) {
  .parent-footer .sm\:flex {
      display: flex;
  }

  .parent-footer .sm\:items-center {
    align-items: center;
  }

  .parent-footer .sm\:justify-between {
    justify-content: space-between;
  }

  .parent-footer .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .parent-footer .md\:py-8 {
      padding-top: 2rem/* 32px */;
      padding-bottom: 2rem/* 32px */;
  }

  .md\:me-6 {
    margin-inline-end: 1.5rem/* 24px */;
  }
}

@media (min-width: 1024px) {
  .lg\:my-8 {
      margin-top: 2rem/* 32px */;
      margin-bottom: 2rem/* 32px */;
  }
}