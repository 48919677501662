.projectDetail-market-parent-wrapper {
    padding: 0;
    margin: 0;
}

.projectDetail-market-wrapper {
    width: 100%;
    min-width: 100%;
    background: transparent;
    opacity: 1;
}

.projectDetail-market-wrapper .projectDetail-line {
    width: 100%;
    margin: 25px auto 0 auto;
    height: 1px;
}

.projectDetail-market-container {
    padding: 10px 0;
    text-align: center;
    width: 100%;
    text-align: center;
    margin: auto;
}
.projectDetail-market-container .projectDetail-market-card {
    width: 100%;
    min-height: 140px;
    border-radius: 10px;
    margin: 0 auto;
}
.projectDetail-market-container .projectDetail-market-tabs {
    text-align: left;
    position: relative;
}
.projectDetail-market-container .projectDetail-market-tabs input {
    position: absolute;
    opacity: 0;
    margin: 0;
    padding: 0;
}

.projectDetail-market-container .projectDetail-market-tabs input + label:hover {
    color: #777;
}
.projectDetail-market-container .projectDetail-market-tabs .projectDetail-tabsShadow {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    position: absolute;
    height: 35px;
    z-index: 0;
    top: 0;
    pointer-events: none;
}

.projectDetail-market-container .projectDetail-content .projectDetail-item {
    display: none; /* Ensure items are hidden by default */
    gap: 25px;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms;
    position: relative; /* Change from absolute to relative to let the height adjust based on content */
    padding: 10px 5px;
}

.projectDetail-market-container section #projectDetail-content-1 {
    opacity: 1;
    visibility: visible;
    width: 94.5%;
}

.projectDetail-market-container #projectDetail-tab-1:checked ~ section #projectDetail-content-1,
.projectDetail-market-container #projectDetail-tab-2:checked ~ section #projectDetail-content-2,
.projectDetail-market-container #projectDetail-tab-3:checked ~ section #projectDetail-content-3,
.projectDetail-market-container #projectDetail-tab-4:checked ~ section #projectDetail-content-4 {
    display: block; /* Show the selected tab content */
    opacity: 1;
    visibility: visible;
    width: 94.5%;
    height: auto; /* Allow height to adjust based on content */
}

.projectDetail-market-container #projectDetail-tab-2:checked ~ section #projectDetail-content-1,
.projectDetail-market-container #projectDetail-tab-3:checked ~ section #projectDetail-content-1,
.projectDetail-market-container #projectDetail-tab-4:checked ~ section #projectDetail-content-1 {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.projectDetail-market-container .projectDetail-market-tabs input + label {
    margin: 12px -4px;
    width: 15%;
    transition: all 200ms;
    text-align: center;
    border: none;
    border-bottom: 0.5pt solid #1d1e22;
    color: #e7e9f5;
    display: inline-flex;
    -webkit-box-pack: center;
    padding: 5pt 10pt;
    vertical-align: top;
    justify-content: flex-start;
    box-shadow: 1px 1px 2px 1px #231f20;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 8pt;
    margin-bottom: 0.5px;
    border-radius: 7px 7px 0 0 !important;
    border-top: 1px solid #3b3d3f;
    letter-spacing: 0.1666666667em !important;
    font-family: "antique-olive" !important;
    background: hsl(232deg 6% 17%);
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

.projectDetail-market-container .projectDetail-content {
    margin: 0px auto;
    text-align: left;
    line-height: 20px;
    /* max-height: 1100px; */
    border-radius: 5px;
    box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    display: block;
    padding: 2.25rem;
    z-index: 2;
    border-top: 1px solid #3b3d3f;
    background: #1a1a1a;
    overflow: hidden; /* Prevents content from overflowing the container */
    word-break: break-all;
    position: relative;
}

.projectDetail-market-container .projectDetail-market-container .projectDetail-market-card {
    max-width: 600px;
    min-height: 140px;
    border-radius: 10px;
    border: none;
    margin: 100 auto;
    z-index: 4 !important;
    position: relative;
    background: linear-gradient(#141e30, #243b55);
    background: #16181a;
    border-top: 1px solid #3b3d3f;
    background: hsl(232deg 18% 8%);
}

.projectDetail-market-container .projectDetail-content p {
    margin: 10px 15px 10px 0;
    letter-spacing: 0.75px;
    font-size: 14pt;
    font-style: normal;
    color: #f0f2fc;
    line-height: 30px;
    margin-top: 10pt;
    font-family: "freight-display-pro";
    font-weight: 300 !important;
}
.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-success,
.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-primary,
.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-default,
.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-docs {
    display: inline-flex;
    color: #fff;
    background-position: bottom center;
    margin-bottom: 0pt !important;
    margin-top: 6pt;
    height: 22pt;
    text-fill-color: transparent !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 0px;
    font-family: "Manrope" !important;
    font-weight: 800 !important;
}

.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-primary {
  background: linear-gradient(
    60deg,
    hsl(202deg 100% 75%),
    hsl(205deg 100% 64%),
    hsl(230deg 100% 75%),
    hsl(270deg 100% 72%)
  );
}

.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-success {
    background: linear-gradient(10deg, #02ce85, #02ceab);
}


.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-default {
    background: linear-gradient(10deg, #f7ec9c, #ff8651);
}

.projectDetail-market-container h2.projectDetail-tab-title.projectDetail-tab-docs {
    background: linear-gradient(10deg, #02ce85, #02ceab);
}   

.projectDetail-market-container .projectDetail-market-tabs input {
    position: relative;
    opacity: 0;
    margin: auto !important;
    padding: 0;
}

.projectDetail-market-container label.projectDetail-tab.projectDetail-tab-primary {
    border-radius: 6pt 0 0 6pt;
}

.projectDetail-market-container #projectDetail-tab-1:checked + label {
    /* color: deepskyblue; */
    color: #f6d359;
}

.projectDetail-market-container #projectDetail-tab-2:checked + label {
    color: #51a14c;
}

.projectDetail-market-container #projectDetail-tab-2:checked ~ .projectDetail-glider {
    background: linear-gradient(90deg, #51a14c 0%, #10c33e 100%);
    box-shadow: 0px 0px 8px 0px rgba(47, 187, 12, 0.62);
}

.projectDetail-market-container #projectDetail-tab-1 ~ .projectDetail-glider {
    left: 1.3%;
}

.projectDetail-market-container #projectDetail-tab-1:checked ~ .projectDetail-glider {
  left: 1.3%;
}

.projectDetail-market-container #projectDetail-tab-2:checked ~ .projectDetail-glider {
  left: 16.7%;
}

.projectDetail-market-container #projectDetail-tab-3:checked ~ .projectDetail-glider {
    left: 32.2%;
    background: linear-gradient(
        90deg,
        #faffcc 0%,
        #f5eea3 10%,
        #ffe48a 40%,
        #ffb54d 65%,
        #ff974d 85%,
        #ff8052 100%
    );
    box-shadow: 0px 0px 8px 0px hsl(17.72deg 100% 70% / 70%);
}

.projectDetail-market-container #projectDetail-tab-4:checked ~ .projectDetail-glider {
    left: 47.4%;
    background: linear-gradient(90deg, #51a14c 0%, #10c33e 100%);
    box-shadow: 0px 0px 8px 0px rgba(47, 187, 12, 0.62);
}

.projectDetail-market-container #projectDetail-tab-3:checked ~ .projectDetail-glider {
    background: linear-gradient(
        90deg,
        #faffcc 0%,
        #f5eea3 10%,
        #ffe48a 40%,
        #ffb54d 65%,
        #ff974d 85%,
        #ff8052 100%
    );
    left: 32.2%;
    box-shadow: 0px 0px 8px 0px hsl(17.72deg 100% 70% / 70%);
}

.projectDetail-market-container #projectDetail-tab-3:checked + label {
    color: #f6d359;
}

.projectDetail-market-container .projectDetail-glider {
  width: 13%;
  padding: 0px 13px;
  height: 4.5px;
  border-radius: 0 0 1px 1px;
  position: absolute;
  box-shadow: 0px 0px 8px 0px hsl(262deg 100% 70% / 70%);
  /* background: linear-gradient(
    113deg,
    hsl(260deg 100% 64%) 0%,
    hsl(190deg 100% 55%) 100%
  ); */
  background: linear-gradient(10deg, #f7ec9c, #ff8651);
  transition: all 200ms;
  top: 40px;
  z-index: 2;
}

.projectDetail-market-container span.projectDetail-numit {
  font-size: 12pt;
  font-family: "muli";
  font-weight: 600;
  text-shadow: 0.5px 0.5px 0.5px #e7e9f5;
  color: #0e101a;
  margin-right: 2px;
  margin-left: -2px;
  padding: 0;
  color: aliceblue;
  text-shadow: 0.5pt 0.5pt 0.5pt #2d3748;
}

.projectDetail-title-text-parent h2 {
    font-size: 21px !important;
    word-break: break-all; /* Breaks words to ensure no overflow */
    white-space: normal; /* Ensures the text wraps to the next line */
}

.projectDetail-title-text-parent p {
    font-size: 14px !important;
    margin-top: 35px !important;
}

.projectDetail-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Create a grid with 5 columns */
    gap: 20px; /* Adjust the gap between images as needed */
}

.projectDetail-gallery img {
    width: 100%; /* Ensure images fill their grid cell */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visual effect */
    cursor: pointer; /* Add cursor pointer on hover */
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.lightbox-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.projectDetail-gallery .doc-link {
    color: #e7e9f5; /* Text color */
    text-decoration: none; /* Remove underline */
    font-size: 14px; /* Font size */
    padding: 10px;
    background: #2d2d2d; /* Background color */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visual effect */
    transition: background 0.3s; /* Transition effect */
    cursor: pointer; /* Add cursor pointer on hover */
}

.projectDetail-gallery .doc-link:hover {
    background: #3a3a3a; /* Change background color on hover */
}