.parent-tokenizedForm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -70px;
}

.tokenizedForm {
    background-color: rgba(255, 255, 255, 0.03);
    width: 100%;
    padding: 50px 10%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.tokenizedForm h1 {
    font-size: 76px;
}

.tokenizedForm button {
    width: 100%;
    padding: 7px 20px;
    margin-top: 50px;
    border-radius: 10px;
    background-color: #F6D36A;
    font-weight: bold;
    font-size: 20px;
    transition: 200ms all ease-in-out;
}

.tokenizedForm button:hover {
    box-shadow: 0 0 20px rgba(246, 211, 106, 0.8);
}

.form-input-divs {
    display: flex;
    align-items: center;
    margin-top: 5%;
    padding: 18px;
    border-radius: 10px;
}

.form-input-divs2 {
    margin-top: 2%;
}

.form-input-divs span {
    flex-basis: 30%;
}

.form-input-divs input {
    flex-grow: 1;
    background-color: #212121;
    border: 1px solid #F6D36A;
    padding: 10px 20px;
    border-radius: 12px;
    color: #fff;
    transition: 200ms all ease-in-out;
}

.form-input-divs input[type="text"]:focus {
    outline: none;
    box-shadow: 0 0 20px rgba(246, 211, 106, 0.55);
}

.form-input-divs select {
    flex-grow: 1;
    border: 1px solid #F6D36A;
    background-color: #212121;
    color: #fff;
    padding: 10px 20px;
    border-radius: 12px;
}

.form-input-divs .shorter-select {
    flex-grow: 0 !important;
    width: 29.5% !important;
}

.form-input-divs select:focus {
    outline: none;
    box-shadow: 0 0 20px rgba(246, 211, 106, 0.55);
}

.form-input-divs2 textarea {
    width: 70%;
    background-color: #212121;
    border: 1px solid #F6D36A;
    border-radius: 12px;
    padding: 10px 20px;
    color: #fff;
}

.form-input-divs2 .descriptionBox {
    width: 75%;
    /* background-color: #212121; */
    border-radius: 12px;
    padding: 10px 20px;
    color: #fff;
}

.form-input-divs textarea:focus{
    outline: none;
    box-shadow: 0 0 20px rgba(246, 211, 106, 0.55);
}

.form-input-divs3 span {
    flex-basis: 30%;
}
  
.form-input-divs3 .market-value-div {
    flex-basis: 45%;
}

.form-input-divs3 .market-value-div input {
    width: 65%;
}

.form-input-divs3 .market-value-div span {
    margin-left: 10px;
}
  
.form-input-divs3 input:last-child {
    flex-grow: 1;
}

.parent-parent-login-success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
.parent-login-success {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 550px;
    max-width: 550px;
    transform: translate(-50%, -50%);
    justify-content: center;
    text-align: center;
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 10px;
  
    animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;
}
  
@keyframes shake {
    10%, 90% {
        transform: translateX(-50%) translateY(-50%);
    }
    
    20%, 80% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    30%, 70% {
        transform: translateX(-52%) translateY(-52%);
    }
    
    40%, 60% {
        transform: translateX(-48%) translateY(-48%);
    }
    
    50% {
        transform: translateX(-50%) translateY(-50%);
    }
}
  
.parent-login-success .svg-parent svg {
    width: 70px;
    margin-bottom: 25px;
}
  
.parent-login-success .title {
    font-size: 24px;
    color: #f6d36a;
    font-weight: bold;
    display: block;
}
  
.parent-login-success .text {
    font-weight: 200;
    font-size: 16px;
    color: #C6C6C6;
}
  
.parent-login-success .button {
    border: none;
    width: 35%;
    padding: 5px 0;
    font-size: 16px;
    background-color: #f6d36a;
    margin-top: 10px;
    border-radius: 5px;
    transition: 300ms all ease-in-out;
}
  
.parent-login-success .button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.6);
}

.parameter-check-icon {
    width: 17px;
    filter: invert(1) brightness(2);
    margin-left: 10px;
}

.parameter-div {
    display: flex;
    align-items: center;
    border-radius: 10px;
    position: relative; /* Ensure it respects positioning */
    z-index: 1; /* Ensure it appears above other elements */
}

.parametersText {
    flex-basis: 35%;
    user-select: none;
    cursor: pointer;
}

.parameter-div input[type="checkbox"] {
    margin-left: 10px;
    position: relative; /* Ensure it respects positioning */
    z-index: 1; /* Ensure it appears above other elements */
}

.parameter-div img {
    margin-left: 10px;
    position: relative; /* Ensure it respects positioning */
    z-index: 1;
}

.parameter-div .market-value-div {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of columns */
    align-items: flex-start;
}

.parameter-div .market-value-div input {
    width: 100%; /* Ensure the input takes up 65% of the market-value-div */
    margin-right: 10px;
}

.parameter-div .column {
    flex: 1 1 50%; /* Ensure each column takes up 50% of the container */
    display: flex;
    align-items: center;
}

.parameter-div .col2 {
    margin-top: 15px;
}

.parameter-div .column input {
    width: 100%; /* Ensure the input takes the full width of the column */
}