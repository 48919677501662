.hero2-parent {
    height: 110vh;
    display: flex;
    justify-content: center; /* horizontally center the button */
    align-items: center; /* vertically center the button */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.03), rgba(0, 0, 0, 1));
}

.hero2-parent .group {
    text-align: center;
    width: 60%;
    user-select: none;
}

.hero2-parent .description {
    color: #646464;
}

.hero2-parent .title_div {
    margin-bottom: 30px;
    margin-top: 30px;
}

.hero2-parent .title_div2 {
    margin-top: 9%;
}

.hero2-parent .title_div h5 {
    background-color: #272727;
    width: 25%;
    padding: 20px;
    border-radius: 10px;
    color: #646464;
    border: 1px solid #272727;
    box-shadow: 0 0 10px rgba(128, 128, 128, 1);
}

.hero2-parent input.checkbox {
    display: none;
}

.hero2-parent .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.hero2-parent .item {
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    transition: 0.5s;
    position: relative;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1));
}

.hero2-parent .item2 {
    padding: 15px 0 15px 0;
}

.hero2-parent .item_long_text {
    padding: 2px 0 2px 0;
}

.hero2-parent .check {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #bbb;
}

.hero2-parent .fai {
    display: block;
    width: 10px;
}

.hero2-parent .fai:nth-child(2) {
    display: none;
}

.hero2-parent .item .title2 {
    margin: 15px 0 0 0;
    font-weight: bold;
    font-size: 16px;
    color: #969696;
}

.hero2-parent .item .subtitle {
    font-size: 14px;
    color: #969696;
}

.hero2-parent input.checkbox:checked + .item {
    /* border: 2px solid #f6d36a; */
    box-shadow: 2px 3px 7px rgba(246, 211, 106, 0.3);
    color: #fff;
}

.hero2-parent input.checkbox:checked + .item .subtitle {
    color: #fff;
}

.hero2-parent input.checkbox:checked + .item .title2 {
    color: #fff;
}

.hero2-parent input.checkbox:checked + .item .check .fai:nth-child(2) {
    display: block;
    color: #fff;
}

.hero2-parent input.checkbox:checked + .item .check .fai:nth-child(1) {
    display: none;
} 