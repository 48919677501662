.db-market-parent-wrapper {
    padding: 0;
    margin: 0;
}

.db-market-wrapper {
    width: 100%;
    min-width: 100%;
    background: transparent;
    opacity: 1;
}

.db-market-wrapper .db-line {
    width: 100%;
    margin: 25px auto 0 auto;
    height: 1px;
}

.db-market-container {
    padding: 10px 0;
    text-align: center;
    width: 100%;
    text-align: center;
    margin: auto;
}
.db-market-container .db-market-card {
    width: 100%;
    min-height: 140px;
    border-radius: 10px;
    margin: 0 auto;
}
.db-market-container .db-market-tabs {
    text-align: left;
    position: relative;
}
.db-market-container .db-market-tabs input {
    position: absolute;
    opacity: 0;
    margin: 0;
    padding: 0;
}

.db-market-container .db-market-tabs input + label:hover {
    color: #777;
}

.db-market-container .db-market-tabs .db-tabsShadow {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    position: absolute;
    height: 35px;
    z-index: 0;
    top: 0;
    pointer-events: none;
}

.db-market-container .db-content .db-item {
    display: none; /* Ensure items are hidden by default */
    gap: 25px;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms;
    position: relative; /* Change from absolute to relative to let the height adjust based on content */
    padding: 10px 5px;
}

.db-market-container section #db-content-1 {
    opacity: 1;
    visibility: visible;
    width: 94.5%;
}

.db-market-container #db-tab-1:checked ~ section #db-content-1,
.db-market-container #db-tab-2:checked ~ section #db-content-2{
    display: block; /* Show the selected tab content */
    opacity: 1;
    visibility: visible;
    width: 94.5%;
    height: auto; /* Allow height to adjust based on content */
}

.db-market-container #db-tab-1:checked ~ section #db-content-2,
.db-market-container #db-tab-2:checked ~ section #db-content-1  {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.db-market-container .db-market-tabs input + label {
    margin: 12px -4px;
    width: 15%;
    transition: all 200ms;
    text-align: center;
    border: none;
    border-bottom: 0.5pt solid #1d1e22;
    color: #e7e9f5;
    display: inline-flex;
    -webkit-box-pack: center;
    padding: 5pt 10pt;
    vertical-align: top;
    justify-content: flex-start;
    box-shadow: 1px 1px 2px 1px #231f20;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 8pt;
    margin-bottom: 0.5px;
    border-radius: 7px 7px 0 0 !important;
    border-top: 1px solid #3b3d3f;
    letter-spacing: 0.1666666667em !important;
    font-family: "antique-olive" !important;
    background: hsl(232deg 6% 17%);
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

.db-market-container .db-content {
    margin: 0px auto;
    text-align: left;
    line-height: 20px;
    /* max-height: 1100px; */
    border-radius: 5px;
    box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    display: block;
    padding: 2.25rem;
    z-index: 2;
    border-top: 1px solid #3b3d3f;
    background: #1a1a1a;
    overflow: hidden; /* Prevents content from overflowing the container */
    word-break: break-all;
    position: relative;
}

.db-market-container .db-market-container .db-market-card {
    max-width: 600px;
    min-height: 140px;
    border-radius: 10px;
    border: none;
    margin: 100 auto;
    z-index: 4 !important;
    position: relative;
    background: linear-gradient(#141e30, #243b55);
    background: #16181a;
    border-top: 1px solid #3b3d3f;
    background: hsl(232deg 18% 8%);
}

.db-market-container .db-content p {
    margin: 10px 15px 10px 0;
    letter-spacing: 0.75px;
    font-size: 14pt;
    font-style: normal;
    color: #f0f2fc;
    line-height: 30px;
    margin-top: 10pt;
    font-family: "freight-display-pro";
    font-weight: 300 !important;
}
.db-market-container h2.db-tab-title.db-tab-success,
.db-market-container h2.db-tab-title.db-tab-primary {
    display: inline-flex;
    color: #fff;
    background-position: bottom center;
    margin-bottom: 0pt !important;
    margin-top: 6pt;
    height: 22pt;
    text-fill-color: transparent !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 0px;
    font-family: "Manrope" !important;
    font-weight: 800 !important;
}

.db-market-container h2.db-tab-title.db-tab-primary {
  background: linear-gradient(
    60deg,
    hsl(202deg 100% 75%),
    hsl(205deg 100% 64%),
    hsl(230deg 100% 75%),
    hsl(270deg 100% 72%)
  );
}

.db-market-container h2.db-tab-title.db-tab-success {
    background: linear-gradient(10deg, #02ce85, #02ceab);
}


.db-market-container h2.db-tab-title.db-tab-default {
    background: linear-gradient(10deg, #f7ec9c, #ff8651);
}

.db-market-container h2.db-tab-title.db-tab-docs {
    background: linear-gradient(10deg, #02ce85, #02ceab);
}   

.db-market-container .db-market-tabs input {
    position: relative;
    opacity: 0;
    margin: auto !important;
    padding: 0;
}

.db-market-container label.db-tab.db-tab-primary {
    border-radius: 6pt 0 0 6pt;
}

.db-market-container #db-tab-1:checked + label {
    /* color: deepskyblue; */
    color: #f6d359;
}

.db-market-container #db-tab-2:checked + label {
    color: #51a14c;
}

.db-market-container #db-tab-2:checked ~ .db-glider {
    background: linear-gradient(90deg, #51a14c 0%, #10c33e 100%);
    box-shadow: 0px 0px 8px 0px rgba(47, 187, 12, 0.62);
}

.db-market-container #db-tab-1 ~ .db-glider {
    left: 1.3%;
}

.db-market-container #db-tab-1:checked ~ .db-glider {
  left: 1.3%;
}

.db-market-container #db-tab-2:checked ~ .db-glider {
  left: 16.7%;
}

.db-market-container .db-glider {
  width: 13%;
  padding: 0px 13px;
  height: 4.5px;
  border-radius: 0 0 1px 1px;
  position: absolute;
  box-shadow: 0px 0px 8px 0px hsl(262deg 100% 70% / 70%);
  /* background: linear-gradient(
    113deg,
    hsl(260deg 100% 64%) 0%,
    hsl(190deg 100% 55%) 100%
  ); */
  background: linear-gradient(10deg, #f7ec9c, #ff8651);
  transition: all 200ms;
  top: 40px;
  z-index: 2;
}

.db-market-container span.db-numit {
  font-size: 12pt;
  font-family: "muli";
  font-weight: 600;
  text-shadow: 0.5px 0.5px 0.5px #e7e9f5;
  color: #0e101a;
  margin-right: 2px;
  margin-left: -2px;
  padding: 0;
  color: aliceblue;
  text-shadow: 0.5pt 0.5pt 0.5pt #2d3748;
}

.db-title-text-parent h2 {
    font-size: 21px !important;
    word-break: break-all; /* Breaks words to ensure no overflow */
    white-space: normal; /* Ensures the text wraps to the next line */
}

.db-title-text-parent p {
    font-size: 14px !important;
    margin-top: 35px !important;
}

.db-first-row {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}

.db-first-row > div:nth-child(1) {
    width: 30%;
}

.db-first-row > div:nth-child(2) {
    width: 27%;
}

.db-first-row > div:nth-child(3) {
    width: 43%;
}

.db-content .user-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.db-content .fist-col-child {
    text-align: center;
}

.db-content .user-image-name {
    border: 1px solid #6D6D6D;
    padding: 30px 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.db-content .name-of-the-user {
    font-size: 20px;
    font-weight: bold !important;
}

.db-content .current-active-project-parent {
    margin-top: 50px;
    border: 1px solid #6D6D6D;
    padding: 15px 0 5px 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-top: 5px;
}

.db-content .current-active-project-parent h3 {
    color: #f6d359;
}

.db-content .current-active-project-parent p {
    font-size: 16px;
    margin-top: -10px;
}

.db-content .db-details-6-child {
    display: flex;
    text-align: left;
    padding: 0 20px;
    margin-top: 5px;
    border: 1px solid #6D6D6D;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.db-content .svg-span {
    align-items: center;
    float: left;
    width: 100%;
}

.db-content .child-svg-span {
    display: flex;
    align-items: center;
}

.db-content .logoImg {
    width: 60px;
}

.db-content .veri-pass-text {
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
    font-weight: 700;
}

.db-content .veri-pass-text-2 {
    font-size: 9px;
    color: #A1A1A1;
    font-weight: 600;
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal; /* Ensure text wraps to the next line */
}

.db-content .overview-middle-side-parent1 {
    border: 1px solid #6D6D6D;
    width: 100%;
    text-align: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.db-content .overview-middle-side-child1 {
    grid-template-columns: 46% 49%;
    display: grid;
    gap: 15px;
    padding: 0 20px;
}

.db-content .overview-metric-title {
    font-size: 21px;
    text-align: center;
    margin-top: 20px;
}

.db-content .overview-middle-side-grandchild {
    border: 1px solid;
    border-image: linear-gradient(to bottom, #6D6D6D, #1a1a1a);
    border-image-slice: 1;
    padding-left: 12px;
    margin-top: 10px;
}

.db-content .data {
    color: #f6d359;
    font-size: 18px !important;
}

.db-content .kpi {
    font-size: 13px !important;
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal; /* Ensure text wraps to the next line */
}

.db-content .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.db-content .arrow {
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: #FFD700; /* Gold color */
    transition: transform 0.3s ease;
}

.db-content .animate-enter {
    opacity: 0;
    transform: translateX(-20px);
}

.db-content .animate-enter-active {
    opacity: 1;
    transform: translateX(0);
}

.db-content .animate-exit {
    opacity: 1;
    transform: translateX(0);
}

.db-content .animate-exit-active {
    opacity: 0;
    transform: translateX(20px);
}

.db-content .secondary-market-table {
    width: 100%;
}

.db-content .secondary-market-table .seller-pic {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}

.db-content .tag-text {
    padding: 8px;
    background-color: #414141;
    text-decoration: none;
    border-radius: 8px;
    color: #f6d36a !important;
    font-weight: bold;
}

.db-content .parent-ask-hbid .ask-text-hbid {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-size: 14px !important;
}

.db-content .parent-ask-hbid .take-btn-hbid {
    cursor: pointer;
    color: #000 !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    background-color: #f6d36a;
    box-shadow: 0px 0px 17px rgba(246, 211, 106, 0.55);
    margin-right: 6.5%;
}

.db-content .bid-button {
    padding: 9px 17px 9px 17px;
    border: none;
    border-radius: 6px;
    background-color: #f6d36a;
    font-weight: bold;
    transition: 250ms all ease-in-out;
    color: #000 !important;
}

.db-content .bid-button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.7);
}

.db-content .make-secondary-button {
    padding: 9px 7px 9px 7px;
    border: none;
    border-radius: 6px;
    background-color: #f6d36a;
    font-weight: bold;
    transition: 250ms all ease-in-out;
}

.db-content .make-secondary-button:hover {
    box-shadow: 0 0 15px rgba(246, 211, 106, 0.7);
}

.db-content .make-secondary-button2 {
    font-size: 12px !important;
    background-color: rgba(246, 211, 106, 0.7);;
}

.db-content .make-secondary-button2:hover {
    box-shadow: none;
}

.db-content .parent-parent-invest-failed .transfer-ownership-select {
    outline: none;
    border: 1px solid #f6d36a;
    width: 100%;
    padding: 10px;
    background-color: #212121;
    color: #fff;
    border-radius: 12px;
}