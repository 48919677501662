.wrapper {
    margin: 0 auto !important;
    /* margin-top: 8% !important; */
    /* No explicit width set - it's responsive based on breakpoints */
    max-width: 540px !important; /* Extra small devices (portrait phones) */
    padding: 8% 0;
    
    @media (min-width: 576px) {
        max-width: 720px !important; /* Small devices (landscape phones) */
    }
    @media (min-width: 768px) {
        max-width: 960px !important; /* Medium devices (tablets) */
    }
    @media (min-width: 992px) {
        max-width: 1140px !important; /* Large devices (desktops) */
    }
    @media (min-width: 1200px) {
        max-width: 1420px !important; /* Extra large devices (large desktops) */
    }
}