.parent-standard-card {
    width: 430px;
    background-color: #323232;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    transition: 300ms all ease-in-out;
}

.parent-standard-card:hover {
    box-shadow: 0 0 40px rgba(246, 211, 106, 0.25);
}

.parent-standard-card .parent-pm-center {
    padding: 0px;
    margin: 0px;
}
  
.parent-standard-card .pm-center {
    /* display: flex;
    height: 25vh;
    align-items: center;
    justify-content: center; */
}
  
.parent-standard-card .pm-center .article-card {
    /* width: 350px; */
    height: 300px;
    /* border-radius: 12px; */
    overflow: hidden;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 300ms;
}
  
.parent-standard-card .pm-center .article-card:hover {
    /* transform: translateY(-2px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
}
  
.parent-standard-card .pm-center .article-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.parent-standard-card .pm-center .article-card .content {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    padding: 30px 20px 20px 20px;
    height: auto;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
}

.parent-standard-card .pm-center .article-card .investment-type {
    top: -5%;
    background: none;
    cursor: pointer;
}

.parent-standard-card .pm-center .article-card .investment-type-text {
    font-size: 12px !important;
    font-weight: bold !important;
    float: right;
    background-color: #414141;
    padding: 1px 30px 1px 30px;
    border-radius: 5px;
    color: #F6D36A !important;
}
  
.parent-standard-card .pm-center .article-card .date,
.parent-standard-card .pm-center .article-card .title {
    margin: 0;
}
  
.parent-standard-card .pm-center .article-card .date {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 4px;
}
  
.parent-standard-card .pm-center .article-card .title {
    font-size: 22px;
    color: #fff;
}



/* Second part of the project detail */
.parent-standard-card .parent-project-details {
    padding: 25px;
}

.parent-standard-card .project-details-1, .project-detail-1-child {
    margin-bottom: -25px;
    justify-content: space-between;
}

.parent-standard-card .project-details-1, .project-detail-1-child .first-child {
    margin-right: auto;
}

.parent-standard-card .project-details-1, .project-detail-1-child .first-child .desc {
    text-align: left;
    font-size: 14px !important;
}

.parent-standard-card .project-details-1, .project-detail-1-child .last-child .desc {
    text-align: right;
    font-size: 14px !important;
}

.parent-standard-card .project-details-1, .project-detail-1-child .first-child span {
    color: #C6C6C6;
    font-size: 12px;
}

.parent-standard-card .project-details-1, .project-detail-1-child .first-child p,
.parent-standard-card .project-details-1, .project-detail-1-child .last-child p {
    font-size: 20px !important;
}


/* Progress Bar */
.parent-standard-card .project-details-2-child {
    justify-content: center;
    align-items: center;
    display: flex;
    /* height: 10vh; */
    padding: 0;
    margin: 10% 0 0 0;
}

.parent-standard-card .progress {
    background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 2px;
    display: flex;
    height: 10px;
    width: 100%;
}
  
.parent-standard-card .progress-value {
    animation: load 3s normal forwards;
    border-radius: 100px;
    background: #f6d36a;
    box-shadow: 0px 0px 7px #f6d36a;
    height: 10px;
    width: 0;
}
  
/* @keyframes load {
    0% { width: 0; }
    100% { width: var(--progress-width); }
} */

.parent-standard-card .progress-value3 {
    animation: load2 3s normal forwards;
    border-radius: 100px;
    background: #f6d36a;
    box-shadow: 0px 0px 7px #f6d36a;
    height: 10px;
    width: 0;
}

@keyframes load2 {
    0% { width: 0; }
    100% { width: 90%; }
}

.parent-standard-card .progress-value3 {
    animation: load3 3s normal forwards;
    border-radius: 100px;
    background: #f6d36a;
    box-shadow: 0px 0px 7px #f6d36a;
    height: 10px;
    width: 0;
}

@keyframes load3 {
    0% { width: 0; }
    100% { width: 49%; }
}

/* Section below progress bar */
.parent-standard-card .project-details-3-child {
    margin-top: 5%;
    width: 100%;
    display: flex;
}

.parent-standard-card .project-details-3-child .svg-span {
    align-items: center;
    float: left;
    width: 100%;
}

.parent-standard-card .project-details-3-child .child-svg-span {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
 
.parent-standard-card .project-details-3-child .svg-span .child-svg-span svg {
    width: 20px;
    padding: 5px 0 5px 0;
}

.parent-standard-card .project-details-3-child .svg-span .child-svg-span span {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    padding: 5px 0 5px 0;
}

.parent-standard-card .project-details-3-child .svg-span .child-svg-span .investment-type {
    color: #F6D36A;
    padding: 5px 15px 5px 15px;
    background-color: #414141;
    border-radius: 7px;
    font-size: 12px;
    font-weight: bold;
}



/* Description Section */
.parent-standard-card .project-details-4-child {
    margin-top: 7%;
}

.parent-standard-card .project-details-4-child .description {
    font-size: 20px;
    font-weight: bold;
}

.parent-standard-card .project-details-4-child .description-details {
    font-size: 14px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 100%;
    display: block;
}

.parent-standard-card .project-details-4-child .read-more {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

/* General Info Section */
.parent-standard-card .general-info-parent {
    display: flex;
    justify-content: space-between;
    margin-bottom: -25px;
    margin-top: 7%;
}

.parent-standard-card .project-details-5-child p:first-child {
    font-size: 20px;
    font-weight: bold;
}

.parent-standard-card .project-details-5-child .dropdown-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #23b499; */
}

.parent-standard-card .project-details-5-child select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background-color: #f6d36a;
    background-image: none;
}

.parent-standard-card .project-details-5-child select::-ms-expand {
    display: none;
}

.parent-standard-card .project-details-5-child .select {
    position: relative;
    display: flex;
    width: 145px;
    height: 2.5em;
    line-height: 3;
    background: #f6d36a;
    overflow: hidden;
    border-radius: 6px;
    margin-top: 7px;
    box-shadow: 0 0 20px rgb(246, 211, 106, 0.5);
}

.parent-standard-card .project-details-5-child select {
    flex: 1;
    padding: 0 15px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1.5px;
    font-weight: 700;
}

.parent-standard-card .project-details-5-child .select::after {
    content: '\2965';
    position: absolute;
    top: -3.5px;
    right: 0;
    padding: 0 1em;
    background: #f6d36a;
    cursor: pointer;
    pointer-events: none;
    transition: 250ms all ease;
    color: #000;
    font-weight: 700;
}

.parent-standard-card .project-details-5-child .select:hover::after {
    color: #000;
}

.parent-standard-card .project-details-5-child .yields-parent {
    margin-top: 7%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.parent-standard-card .project-details-5-child .yields-parent .yields-children {
    justify-content: center;
}

.parent-standard-card .project-details-5-child .yields-parent .value {
    font-size: 16px;
}

.parent-standard-card .project-details-5-child .yields-parent .desc {
    font-size: 11.5px;
    margin-top: -5%;
    color: #A1A1A1;
    line-height: 18px;
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal; /* Ensure text wraps to the next line */
}



/* Verification Passed */
.parent-standard-card .project-details-6-child {
    margin-top: 5%;
    width: 100%;
    display: flex;
}

.parent-standard-card .project-details-6-child .svg-span {
    align-items: center;
    float: left;
    width: 100%;
}

.parent-standard-card .project-details-6-child .child-svg-span {
    display: flex;
    align-items: center;
}

.parent-standard-card .project-details-6-child .svg-span .child-svg-span .logoImg {
    width: 60px;
    padding: 5px 0 5px 0;
}

.parent-standard-card .project-details-6-child .child-svg-span .veri-pass-text {
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
    font-weight: 700;
}

.parent-standard-card .project-details-6-child .child-svg-span .veri-pass-text-2 {
    font-size: 12px;
    color: #A1A1A1;
    font-weight: 600;
}



/* Research Project Button */
.parent-standard-card .project-details-7-child {
    justify-content: center;
    align-items: center;
    display: flex;
    /* height: 10vh; */
    padding: 0;
    margin: 7% 0 0 0;
}

.parent-standard-card .project-details-7-child .Link {
    width: 100%;
}

.parent-standard-card .project-details-7-child button {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: #f6d36a;
    font-size: 18px;
    font-weight: 700;
    border: none;
    transition: 300ms all ease-in-out;
    cursor: pointer;
}

.parent-standard-card .project-details-7-child button:hover {
    box-shadow: 0 0 20px rgba(246, 211, 106, 0.60);
}

.incoterms-parent {
    margin-top: -3px !important;
    margin-bottom: -10px !important;
}

.incoterms-parent p {
    font-size: 14px !important;
}